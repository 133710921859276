import React from "react"
import "./footer.scss"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulGalleryMax {
        imagebundle2 {
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <footer className="footer-container">
      <img
        className="footer-img"
        style={{}}
        src={data.contentfulGalleryMax.imagebundle2.file.url}
      />
    </footer>
  )
}

export default Footer
