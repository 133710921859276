import React from "react"
import Header from "../components/header/Header"
import Navbar from "../components/navbar/navbar"
import Footer from "../components/footer/footer"
import "./details.scss"
import { graphql } from "gatsby"
import { options } from "../utils/queryUtils"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export const query = graphql`
  query MyQuery {
    allContentfulDetails {
      edges {
        node {
          itineraryDetails {
            raw
          }
        }
      }
    }
    contentfulDetails {
      itineraryDetails {
        raw
      }
      itinerary {
        file {
          url
        }
      }
      date {
        id
        file {
          url
        }
      }
    }
  }
`

const Details = ({ data }) => {
  return (
    <>
      <Header />
      <Navbar />
      <h1 className="page-title">Details</h1>
      <div className="details-container">
        <img
          className="contentful-date-img"
          src={data.contentfulDetails.date.file.url}
          alt="itinerary-date"
        />
        <img
          className="contentful-itinerary"
          src={data.contentfulDetails.itinerary.file.url}
          alt="itinerary-details"
        />
        <div className="details-container__text">
          {renderRichText(
            data.allContentfulDetails.edges[0].node.itineraryDetails,
            options
          )}
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Details
